import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  TableWrap,
  TableWrapActions,
  TableWrapRight,
  TableWrapLeft,
  RemoveItem,
} from '../../components'
import { InviteUser } from '../../containers'
import { OnlyOrganizationAdmin } from '../../services/auth'
import { fetchOrganizationInvites, sendInvite, deleteOrganizationInvite } from '../../actions/organizations'
import moment from 'moment'

const inviteStatuses = {
  pending: 'Convite Pendente',
  accepted: 'Convite Aceito',
  expired: 'Convite Expirado',
}

const StatusAdmin = (props) => {
  if (props.status === 'pending') {

    return (
      <div>
        <div>
          {inviteStatuses[props.status]}
        </div>
        <a onClick={props.resendInvite}>
          Reenviar Convite
        </a>
      </div>
    )
  }

  return <Status {...props} />
}

const Status = (props) =>
  <span>{inviteStatuses[props.status]}</span>

const InviteStatus = OnlyOrganizationAdmin(StatusAdmin, Status)

const RemoveInvite = OnlyOrganizationAdmin(
  props => (
    <RemoveItem
      section="convite"
      id={props.userId}
      onRemove={props.onRemove}
    />
  )
)

class OrganizationInvitesList extends React.Component {
  constructor(props) {
    super(props)
    this.model = {
      email: { name: 'E-mail' },
      created: {
        name: 'Enviado em',
        renderer: (date) => <span>
          {moment(date + '+00:00').format('DD/MM/YYYY HH:mm')}
        </span>,
      },
      status: {
        name: 'Status',
        renderer: (status, obj) => (
          <InviteStatus
            organizationId={this.props.match.params.id}
            status={status}
            resendInvite={() => this.props.sendInvite(this.props.match.params.id, obj.email)}
          />
        ),
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (item, obj) =>
          <RemoveInvite
            userId={obj.id}
            onRemove={() => this.props.deleteOrganizationInvite(this.props.match.params.id, obj.id)}
            organizationId={this.props.match.params.id}
          />
        ,
      },
    }
  }

  render () {
    return (
      <div hidden={!this.props.organizationInvites.allIds.length}>
        <TableWrap
          model={this.model}
          source={this.props.organizationInvites}
          actionFetch={this.props.fetchOrganizationInvites}
          location={this.props.location}
          accountId={this.props.match.params.id}
        >
          <TableWrapActions>
            <TableWrapLeft>
              Convites
            </TableWrapLeft>

            <TableWrapRight>
              <InviteUser
                organizationId={this.props.match.params.id}
                formName="Invites"
              />
            </TableWrapRight>

          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
}

StatusAdmin.propTypes = {
  resendInvite: PropTypes.func,
  status: PropTypes.string,
}

Status.propTypes = {
  status: PropTypes.string,
}

InviteStatus.propTypes = {
  status: PropTypes.string,
  resendInvite: PropTypes.func,
}

OrganizationInvitesList.propTypes = {
  id: PropTypes.string,
  params: PropTypes.object,
  status: PropTypes.string,
  location: PropTypes.object,
  resendInvite: PropTypes.func,
  organizationInvites: PropTypes.object,
  fetchOrganizationInvites: PropTypes.func,
  sendInvite: PropTypes.func,
  deleteOrganizationInvite: PropTypes.func,
}

const mapStateToProps = ({ organizationInvites }) => ({ organizationInvites })

export default connect(mapStateToProps, {
  fetchOrganizationInvites,
  sendInvite,
  deleteOrganizationInvite,
})(OrganizationInvitesList)
