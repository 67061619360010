import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import AccountList from './AccountList'
import classnames from 'classnames'
import { Icon } from "../Icon"
import { Loading } from "../Loading"
import { Add, Business, Person, Public, Search } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

export class SwitchAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      value: this.props.currentAccount.name,
      lastValue: this.props.currentAccount.name,
      currentAccount: this.props.currentAccount,
      endFetch: false,
      mruVisible: true,
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.keyUp = this.keyUp.bind(this)
    this.clickOut = this.clickOut.bind(this)
    this.close = this.close.bind(this)
    this.leave = this.leave.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentAccount !== this.props.currentAccount) {
      this.setNextValues(nextProps)
    }
  }

  setNextValues(nextProps) {
    this.setState({
      value: nextProps.currentAccount.name,
      lastValue: nextProps.currentAccount.name,
      currentAccount: nextProps.currentAccount,
    })
  }

  keyUp({ keyCode }) {
    if (keyCode === 27) {
      this.leave()
    }
  }

  clickOut() {
    if (document.activeElement !== this.input) {
      this.leave()
    }
  }

  handleClick(item) {
    const lastValue = item.name
    const value = item.name
    this.setState({ value, lastValue, currentAccount: item })
    this.close()

    if (this.props.onClick) {
      this.props.onClick(item)
    }
  }

  handleChange(e) {
    this.setState({ value: e.target.value, endFetch: true, mruVisible: false }, () => {
      if (!this.state.value) {
        this.setState({ mruVisible: true })
      }
    })
    this.open()


    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  handleFocus() {
    this.setState({ value: '' })
    this.open()
  }

  open() {
    this.setState({ open: true })

    document.addEventListener('keyup', this.keyUp)
    document.addEventListener('click', this.clickOut)
  }

  leave() {
    this.setState(oldState => ({ ...oldState, value: oldState.lastValue, mruVisible: true }))
    this.props.clearSearch()
    this.close()
  }

  close() {
    // this.input.blur &&
    //   this.input.blur()

    this.setState({ open: false, endFetch: false })

    document.removeEventListener('keyup', this.keyUp)
    document.removeEventListener('click', this.clickOut)
  }

  isEmpty() {
    return !this.props.searchItems.length && this.state.endFetch
  }

  render() {
    const { open } = this.state
    const switchClass = classnames('switch-account', { open })
    const currentId = this.state.currentAccount.id || false

    return (
      <div className={switchClass}>
        {this.state.open
          ? <Search style={{ position: 'absolute', top: 5, left: 10 }} />
          : <React.Fragment>
            {this.state.currentAccount.type === 'person' &&
              <Person style={{ position: 'absolute', top: 5, left: 10 }} />
            }

            {this.state.currentAccount.type === 'business' &&
              <Business style={{ position: 'absolute', top: 5, left: 10 }} />
            }

            {this.state.currentAccount.type === 'public' &&
              <Public style={{ position: 'absolute', top: 5, left: 10 }} />
            }

            {this.state.currentAccount.type === 'organization' &&
              <Business style={{ position: 'absolute', top: 5, left: 10 }} />
            }
          </React.Fragment>
        }

        <input
          ref={(input) => { this.input = input }}
          type="text"
          className="input"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          value={this.state.value || ''}
        />

        {!!this.state.value && this.state.open &&
          <span className="clear-search" onClick={this.leave}>
            <Icon name="cancel" />
          </span>
        }

        <div className="list">
          <Loading loading={this.props.isLoading} />

          {!!this.props.user &&
            <AccountList
              items={[this.props.user]}
              onClick={this.handleClick}
              currentAccount={currentId}
            />
          }

          {!!this.props.organizations.length &&
            <AccountList
              items={this.props.organizations}
              onClick={this.handleClick}
              currentAccount={currentId}
            />
          }

          {!!this.props.searchItems.length && !this.state.mruVisible &&
            <AccountList
              items={this.props.searchItems}
              onClick={this.handleClick}
              currentAccount={currentId}
            />
          }

          {this.isEmpty() &&
            <div>
              <strong>Nenhuma conta encontrada</strong>
            </div>
          }

          {!!this.props.lastAccounts.length && this.state.mruVisible &&
            <div>
              <AccountList
                items={this.props.lastAccounts}
                onClick={this.handleClick}
                currentAccount={currentId}
              />
            </div>
          }
          {/*<Link to="/organizations/new">*/}
          {/*  <Add style={{ marginLeft: 20, marginRight: 15 }} />*/}

          {/*  <Typography style={{ fontWeight: 'bold' }}>*/}
          {/*    Criar Organização*/}
          {/*  </Typography>*/}
          {/*</Link>*/}
        </div>
      </div>
    )
  }
}

SwitchAccount.defaultProps = {
  searchItems: [],
  lastAccounts: [],
  organizations: [],
}

SwitchAccount.propTypes = {
  currentAccount: PropTypes.object,
  user: PropTypes.object,
  organizations: PropTypes.array,
  searchItems: PropTypes.array,
  lastAccounts: PropTypes.array,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  clearSearch: PropTypes.func,
  isLoading: PropTypes.bool,
}
