import React from 'react'
import { ProfileContainer, AccountControl, NotificationsHeader } from '../containers'
import Notification from '../components/Notification'
import { userIsAuthenticatedRedir } from '../services/auth';

export const HeaderMenu = userIsAuthenticatedRedir((props) => {
    return (
        <div className="tools">
            <AccountControl />
            <Notification />
            <NotificationsHeader />
            <ProfileContainer />
        </div>
    )
})
