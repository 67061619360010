import React, { memo } from 'react'

import { Content } from '../../components'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const EditOrganizations = memo(props => {

  const auth = useSelector(state => state.auth)
  const account = useSelector(state => state.account)

  const haveUserRole = auth?.data?.organizations[account[0]?.id]?.role === "user"

  const userRoutes = [
    {
      route: `/organizations/${props.match.params.id}`,
      name: 'Detalhes',
    },
    {
      route: `/organizations/${props.match.params.id}/users`,
      name: 'Usuários',
    },
    {
      route: `/organizations/${props.match.params.id}/products-role`,
      name: 'API de Certificados',
      beta: true,
    },
  ];

  const adminRoutes = [
    {
      route: `/organizations/${props.match.params.id}`,
      name: 'Detalhes',
    },
    {
      route: `/organizations/${props.match.params.id}/users`,
      name: 'Usuários',
    },
    {
      route: `/organizations/${props.match.params.id}/notifications`,
      name: 'Notificações',
    },
    {
      route: `/organizations/${props.match.params.id}/products-role`,
      name: 'API de Certificados',
      beta: true,
    },
  ];

  const allowedRoutes = [
    `/organizations/${props.match.params.id}`,
    `/organizations/${props.match.params.id}/users`,
    `/organizations/${props.match.params.id}/notifications`,
    `/organizations/${props.match.params.id}/products-role`,
  ];

  const submenu = allowedRoutes.includes(location.pathname)
    ? haveUserRole
      ? userRoutes
      : adminRoutes
    : [];

  const { setTitle } = props

  return (
    <Content submenu={submenu} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={props =>
              <route.component
                routes={route.routes}
                setTitle={setTitle}
                title={route.name}
                {...props}
              />
            }
          />
        )}
      </Switch>
    </Content>
  )
})
