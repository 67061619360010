import store from 'store'
import { push } from 'connected-react-router'
import jwtDecode from 'jwt-decode'
import { fetch } from '../services/fetch'
import { fetchMruAccounts } from './account'
import { setMessage, ERROR, INFO } from '../modules/messages'

export const LOGIN_USER_REQUEST = 'auth/LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'auth/LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'auth/LOGIN_USER_FAILURE'
export const LOGOUT_USER = 'auth/LOGOUT_USER'
export const UPDATE_NOTIFICATIONS_COUNT = 'auth/UPDATE_NOTIFICATIONS_COUNT';

const checkHttpStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  const error = new Error(response.statusText)

  error.response = response

  return response
}

export function loginUserSuccess (token, userNotifyNumber) {
  store.set('token', token)
  store.set('userNotifyNumber', userNotifyNumber);
  const decodedToken = jwtDecode(token)
  const analytics = {
    // eventType: EventTypes.identify,
    eventPayload: {
      userId: decodedToken.user.id,
      traits: {
        email: decodedToken.user.email,
        firstName: decodedToken.user.first_name,
        lastName: decodedToken.user.last_name || '',
        phone: decodedToken.user.phone,
      },
    },
  }
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      token,
      decodedToken,
      userNotifyNumber
    },
    meta: {
      analytics,
    },
  }
}

export function loginUserFailure (error) {
  store.remove('token')

  return dispath => {
    // console.log(error);
    dispath(setMessage(ERROR, (error.message !== undefined ? error.message : error.title)))

    return dispath({
      type: LOGIN_USER_FAILURE,
      payload: error,
    })
  }
}

export function loginUserRequest () {
  return {
    type: LOGIN_USER_REQUEST,
  }
}

export function logout () {
  store.remove('token')
  return {
    type: LOGOUT_USER,
    // meta: { analytics: { eventType: EventTypes.reset } },
  }
}

export function logoutAndRedirect () {
  return (dispatch) => {
    dispatch(logout())
    // dispatch(push('/login'))
  }
}

export function loginUser (data, redirect = '/') {
  return (dispatch) => {
    dispatch(loginUserRequest())
    return fetch('/token', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        grant_type: 'password',
        ...data,
      }),
    })
      .then(checkHttpStatus)
      .then(res => res.json())
      .then(res => {
        try {
          if (res.access_token) {
            const token = res.access_token
            const updateInfo = !!res.update_info
            const userNotifyNumber = res.dispatched_notifications_count

            jwtDecode(token) // somente para validar o token
            dispatch(loginUserSuccess(token, userNotifyNumber))
            dispatch(fetchMruAccounts(token))

            if (updateInfo) {
              dispatch(push('/settings/profile'))
              dispatch(setMessage(INFO, 'Verifique se seu perfil está atualizado.'))
            } else {
              dispatch(push(redirect))
            }
          } else {
            dispatch(loginUserFailure(res))
          }
        } catch (e) {
          dispatch(loginUserFailure(e))
        }
        return res
      })
      .catch(error => {
        if (error.code === 171) {
          return error
        }

        dispatch(loginUserFailure(error))
      })
  }
}

export function refreshToken () {
  return (dispatch) => (
    fetch('/token', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ grant_type: 'refresh_token' }),
    })
      .then(checkHttpStatus)
      .then(res => res.json())
      .then(res => {
        try {
          const token = res.access_token
          const userNotifyNumber = res.dispatched_notifications_count
          jwtDecode(token) // somente para validar o token
          dispatch(loginUserSuccess(token, userNotifyNumber))
          dispatch(fetchMruAccounts(token))
        } catch (e) {
          dispatch(loginUserFailure({
            response: {
              status: 403,
              statusText: 'Invalid token',
            },
          }))
        }

        return res
      })
  )
}

export const tokenHandler = dispatch => response => {
  try {
    const headers = response.headers
    const jwt = headers.get('X-JWT')
    const userNotifyNumber = store.get('userNotifyNumber')
    if (!!jwt) {
      dispatch(loginUserSuccess(jwt, userNotifyNumber))
    }
  } catch (e) {
    // no-p
  }
}

export function updateNotificationsCount (count) {
  store.set('userNotifyNumber', count);
  return {
    type: UPDATE_NOTIFICATIONS_COUNT,
    payload: count,
  };
}
