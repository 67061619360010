import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect, useSelector } from 'react-redux'
import { RemoveItem, Loading, Pagination, Help, AboutValidation } from '../components'
import { Link } from 'react-router-dom'
import { fetchDomains, deleteDomain } from '../actions/domains'
import { withAccountId } from './AccountContainer'
import { ExpandLess, ExpandMore, SubdirectoryArrowRight } from '@material-ui/icons'
class DomainsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isOpen: {},
      orderBy: '',
      page: 1,
    }
    this.onRemove = this.onRemove.bind(this)
    this.validateUserRole = this.validateUserRole.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.doFetch = this.doFetch.bind(this)
  }

  componentDidMount () {
    this.doFetch()
  }

  validateUserRole () {
    return this.props.auth?.data?.organizations[this.props.accountId]?.role !== "user"
  }

  onRemove (id) {
    const hard = true
    this.props.deleteDomain(id, hard)
      .then(() => this.props.fetchDomains(this.props.accountId))
  }

  doFetch () {
    if (!this.props.isLoading) {
      this.props.fetchDomains(this.props.accountId, { ...this.queryParams() })
    }
  }

  queryParams () {
    const params = {}

    params.page = this.state.page || 1
    params.page_size = this.props.page_size || ''

    return params
  }

  handleChangePage (newPage) {
    this.setState({ page: newPage }, () => {
      this.doFetch()
    })
  }

  handleClick (id) {
    const { isOpen } = this.state
    isOpen[id] = !isOpen[id] || false
    this.setState({ isOpen })
  }

  renderDomains (item) {
    const domains = []

    domains.push(this.renderRow(item))
    domains.push(item.subdomains.map((subItem) => this.renderRow(subItem, item)))

    return domains
  }

  renderRow (item, parent = {}) {
    const isSubdomain = !!parent.id
    const classItem = classnames({
      subdomain: isSubdomain,
      open: this.state.isOpen[parent.id],
    })

    const validateDomain = !item.is_validated && !item.is_public && !isSubdomain
    const validateSubDomain = !item.is_validated && parent.is_public && isSubdomain

    return (
      <tr key={item.id} className={classItem}>
        <td width="40%">
          <span className="align-items">

            {!isSubdomain && (item.subdomains.length > 0) &&
              <span className="add-box" onClick={() => this.handleClick(item.id)}>
                {this.state.isOpen[item.id]
                  ? <ExpandLess />
                  : <ExpandMore />
                }
              </span>
            }

            {isSubdomain &&
              <SubdirectoryArrowRight />
            }

            {item.name}
          </span>
        </td>

        <td className="text-left">
          {item.is_validated &&
            <span>Validado</span>
          }

          {(validateDomain || validateSubDomain) &&
            <span>Aguardando Validação <br />
              <Link
                to={`/devices/validation/${item.id}`}
              >
                Validar
              </Link>
            </span>
          }

        </td>
        <td className="actions">
          <RemoveItem
            section="domínio"
            id={item.id}
            onRemove={this.onRemove}
            additional_information="TODOS os aplicativos e informações referentes a scans serão apagados!"
          />
        </td>
      </tr>
    )
  }

  render () {
    return (
      <div className="table-wrap">
        <Loading loading={this.props.isLoading} />
        <div className="actions">
          <div className="left">
            Lista de domínios
          </div>

          {
            this.validateUserRole() &&
            <div className="right">
              <Link to="/devices/domains/new">
                Adicionar domínio
              </Link>
            </div>
          }

        </div>

        <table className="table table-no-border">
          <thead>
            <tr>
              <th>Nome</th>
              <th>
                Status
                <Help title="Por que validar um domínio">
                  <AboutValidation />
                </Help>
              </th>
              <th key="action"></th>
            </tr>
          </thead>

          {_.isEmpty(this.props.results) &&
            <tbody>
              <tr>
                <td colSpan="3" style={{ textAlign: 'center' }}>
                  <span>
                    Não existem dados para essa lista
                  </span>
                </td>
              </tr>
            </tbody>
          }

          <tbody>

            {this.props.results.map((item) => this.renderDomains(item))}

          </tbody>
        </table>

        <Pagination
          count={this.props.count || 0}
          pageSize={this.props.page_size || 0}
          page={this.props.page || this.props.page}
          onPageChange={(newPage) => {
            this.handleChangePage(newPage)
          }}
        />
      </div>
    )
  }
}

DomainsList.propTypes = {
  accountId: PropTypes.string.isRequired,
  fetchDomains: PropTypes.func,
  deleteDomain: PropTypes.func,
  isLoading: PropTypes.bool,
  results: PropTypes.array,
  count: PropTypes.number,
  location: PropTypes.object,
  page_size: PropTypes.number,
  page: PropTypes.number,
}

function mapStateToProps ({ domains, auth }) {
  return {
    ...domains,
    auth
  }
}

export default connect(mapStateToProps, { fetchDomains, deleteDomain })(withAccountId(DomainsList))
